import { useState } from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import "../styles/UserScan.css";
import { useAuthContext } from "@asgardeo/auth-react";
import { RESOURCE_SERVER_URLS } from "../data/configs/spl-config";
import { FormControlLabel, Tooltip, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { green } from "@mui/material/colors";
import { red } from "@mui/material/colors";
import CircularLoading from "./Loading";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const UserScanning = () => {
  const { httpRequest } = useAuthContext();

  const [email, setEmail] = useState("");
  const [projectKey, setProjectKey] = useState("");
  const [isPartner, setIsPartner] = useState(false);
  const [responseData, setResponseData] = useState([] as any[]);
  const [showLoading, setShowLoading] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isProjectKeyError, setIsProjectKeyError] = useState(false);

  const userScanAPI = async () => {
    if (email === "") {
      setIsEmailError(true);
      setResponseData([]);
    } else if (projectKey === "") {
      setIsProjectKeyError(true);
      setResponseData([]);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setIsEmailError(true);
      setResponseData([]);
    } else {
      setIsEmailError(false);
      setIsProjectKeyError(false);
      const bffUrl = RESOURCE_SERVER_URLS[0] + `/scan-user`;
      try {
        setShowLoading(true);
        const response = await httpRequest({
          url: bffUrl,
          method: "POST",
          headers: {
            accept: "application/json",
          },

          data: {
            email: email,
            subscriptionKey: projectKey,
            isPartner: false,
          },
        });
        if (response.status === 201) {
          setResponseData(response.data);
        }
        setShowLoading(false);
      } catch (error: any) {
        setShowLoading(false);
        setResponseData([]);
      }
    }
  };

  const checkHandler = () => {
    setIsPartner(!isPartner);
  };

  const copyToClipboard = (value: any) => {
    let isCopy = copy(value);
  };

  const handleEmailFiledChanges = (event: any) => {
    setEmail(event.target.value);
    setIsEmailError(false);
    setIsPageLoading(true);
  };

  const handleProjectFiledChanges = (event: any) => {
    setProjectKey(event.target.value);
    setIsProjectKeyError(false);
    setIsPageLoading(true);
  };

  return (
    <>
      <div className="background">
        <div>
          <h4>User Scan</h4>
        </div>
        <br />
        <div>
          <TextField
            id="email"
            size="small"
            label="Email"
            variant="outlined"
            required
            helperText="Please provide a valid Email"
            onChange={(event) => handleEmailFiledChanges(event)}
            color="primary"
          />
          <TextField
            className="project-key-input"
            id="projectKey"
            size="small"
            required
            label="Subscription Key"
            variant="outlined"
            helperText="Please provide a valid subscription key"
            onChange={(event) => handleProjectFiledChanges(event)}
          />

          <FormControlLabel
            id="isPartner"
            className="is-partner-input"
            control={<Checkbox />}
            label="Is Partner"
            checked={isPartner}
            onChange={checkHandler}
          />
          <Button
            className="button-input"
            variant="contained"
            size="small"
            color="secondary"
            onClick={userScanAPI}
            disabled={!isPageLoading || isEmailError || isProjectKeyError}
          >
            Analyze
          </Button>
          {isEmailError && (
            <Typography variant="body1" color="error">
              {isEmailError && "Please provide valid Email address"}
            </Typography>
          )}
          {isProjectKeyError && (
            <Typography variant="body1" color="error">
              {isProjectKeyError && "Please provide valid subscription key"}
            </Typography>
          )}
        </div>
      </div>

      <div className="background">
        <CircularLoading openLoading={showLoading} />
        {responseData.map((responseObject) => (
          <div className="response-base line-margin">
            <strong>{responseObject.system}</strong>
            <br />
            <div className="response-types line-margin">
              {responseObject.systemResult?.map((systemResult: any) => (
                <p>
                  {systemResult.state ? (
                    <CheckCircleIcon sx={{ color: green[700] }} />
                  ) : (
                    <CancelRoundedIcon sx={{ color: red[700] }} />
                  )}
                  <span className="information-response">
                    {systemResult.label}
                  </span>
                  {!systemResult.state ? (
                    <div className="response-information line-margin">
                      <div>
                        <table>
                          <tr className="information-issue">
                            <td>
                              <strong>Issue</strong>
                            </td>
                            <td className="information-response">
                              {systemResult.information.issue}
                            </td>
                          </tr>
                          <tr className="information-solution">
                            <td>
                              <strong>Solution</strong>
                            </td>
                            <td className="information-response">
                              {systemResult.information?.solution}
                              <a
                                href={systemResult.information?.documentation}
                                target="_blank"
                              >
                                (instructions)
                              </a>
                            </td>
                          </tr>

                          {systemResult.information?.invitationUrl ? (
                            <tr>
                              <td>
                                <strong>Invitation</strong>
                              </td>
                              <td className="information-response">
                                You can copy the invitation link and share it
                                with the user&nbsp;
                                <strong>
                                  directly without CC-ing anyone or group.
                                </strong>
                                &nbsp; It is confidential. To copy, double-click
                                on the icon.&nbsp;
                                <Tooltip title="Copy here">
                                  <ContentCopyIcon
                                    onClick={() => {
                                      copyToClipboard(
                                        systemResult.information?.invitationUrl
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </table>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </p>
              ))}
              <hr />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserScanning;
