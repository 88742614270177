import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, CircularProgress } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface CaseCardPropsInterface {
    state: string;
    color: string;
    data: any;
    loading: boolean;
    error: any;
    setCaseState: (state: string) => void;
}

export default function CaseCard(caseCardProps: CaseCardPropsInterface) {

    function handleCardClick(): void {
        caseCardProps.setCaseState(caseCardProps.state);
    }

    return (
        <Card sx={{ width: 250, height: 150, cursor: "pointer", '&:hover': { backgroundColor: '#f9dcc5' }, display: "flex", alignContent: "center" }}>
            <CardActionArea onClick={handleCardClick} disabled={caseCardProps.loading || caseCardProps.error}>
                <CardContent sx={{
                    display: "flex", justifyContent: "center",
                    flexDirection: !caseCardProps.error && !caseCardProps.loading ? "column" : undefined
                }}>
                    {
                        caseCardProps.loading ?
                            <CircularProgress color="primary" />
                            : caseCardProps.error ?
                                <ErrorOutlineIcon fontSize="large" color='error' />
                                : caseCardProps.data &&
                                <>
                                    <Typography align='center' gutterBottom variant="h3" component="div" color={caseCardProps.color}>
                                        {caseCardProps.data.count}
                                    </Typography>
                                    <Typography align='center' gutterBottom variant="h5" component="div">
                                        {caseCardProps.state}
                                    </Typography>
                                </>
                    }
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
