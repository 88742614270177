import { Avatar, Box, Drawer, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useEffect } from "react";
import { RESOURCE_SERVER_URLS } from "../data/configs/spl-config";
import { useGetApi } from "../data/hooks/api";
import * as Types from "../data/utils/types";
import { ErrorComponent } from "../pages/ErrorPage";
import { ItemNotFoundComponent } from "../pages/ItemNotFound";
import LinearLoading from "./LinearLoading";
interface TeamMembersDrawerProps {
   open: boolean;
   onClose: () => void;
   teamName: string;
   teamId: string;
}

const TeamMembersDrawer = ({ open, onClose, teamName, teamId }: TeamMembersDrawerProps) => {

   const apiUrl = RESOURCE_SERVER_URLS[0] + `/abt-team-members?teamId=${teamId}`;

   const { data, loading, error, getApiData } = useGetApi<Types.ABTTeamMembersDetails[]>({
      url: apiUrl,
      headers: { accept: "application/json" },
   });

   useEffect(() => {
      getApiData();
   }, [])

   const capitalizeFirstLetter = (item: string) => {
      return item.charAt(0).toUpperCase() + item.slice(1);
   }

   const MembersList = () => {
      return (
         <List>
            {data?.map((member, index) => (
               <ListItem key={index}>
                  <ListItemAvatar>
                     <Avatar alt={member.name} src={member.employeeThumbnail} />
                  </ListItemAvatar>
                  <ListItemText
                     primary={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                           {member.name.replace(' ⓦ', '')}
                           {member.role && (
                              <span
                                 style={{
                                    marginLeft: '8px',
                                    padding: '3px 8px',
                                    backgroundColor: '#e0f7fa',
                                    borderRadius: '10px',
                                    fontSize: '0.75rem',
                                    fontWeight: 'bold',
                                    color: '#00796b',
                                 }}
                              >
                                 {capitalizeFirstLetter(member.role)}
                              </span>
                           )}
                        </div>
                     }
                     secondary={member.email}
                  />
               </ListItem>
            ))}
         </List>
      )
   }

   return (
      <>
         <Drawer anchor="right" open={open} onClose={onClose}>
            <Box sx={{ width: 400, padding: 5, marginTop: 10 }}>
               <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "20px", textDecoration: "underline" }}>
                  <Typography variant="h4" fontWeight={'bold'}>{teamName}</Typography>
               </Box>
               <Typography variant="h5" gutterBottom>
                  Team Members
               </Typography>
               {loading
                  ? <LinearLoading />
                  : error
                     ? error.status == 404
                        ? <ItemNotFoundComponent />
                        : <ErrorComponent />
                     : data && <MembersList />}
            </Box>
         </Drawer>
      </>
   );
};

export default TeamMembersDrawer;
